import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import { Element as ScrollElement, Link as ScrollLink } from "react-scroll"
import Buttons from "../../components/common/button"
import CarouselComponent from "../../components/common/carousel-component"
import FormBuilder from "../../components/common/FormBuilder/Form-Builder"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import Slide from "../../components/utility/slide_content"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/mspawards-winner.scss"
import "../../styles/pages/mspawards.scss"
import Popper from "./../../assets/images/party-popper.svg"

const AwardsWinner2023 = () => {
  const [popUp, setPopUp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const showpopup = () => {
    setPopUp(true)
  }

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
  ]

  const responsiveSpeaker = {
    mobile2: {
      breakpoint: { max: 1175, min: 900 },
      items: 2,
    },
    mobile3: {
      breakpoint: { max: 900, min: 425 },
      items: 1.4,
    },
    mobile4: {
      breakpoint: { max: 425, min: 0 },
      items: 1,
    },
  }

  const AwardCards = ({ card, item, images, idx }) => {
    return (
      <Col lg={4} className={`award-card position-relative x-${idx + 1}`}>
        <SVGIcon name="winnerLeaves" className="position-absolute leaves" />
        <div className="upper-wrapper">
          <div
            className={`d-flex justify-content-center align-items-center upper-card x-${idx +
              1}`}
          >
            <img src={images[idx].url} alt="image" />
          </div>
        </div>

        <div
          className={`lower-card d-flex align-items-center ${
            !card.text?.length ? "justify-content-center" : "pad-left"
          }  x-${idx + 1}`}
        >
          {card.text?.length ? (
            <>
              <div>
                <p className="p20 fw-500 text-white m-0">{card.text[idx]}</p>
                <p className="p16 msp-company m-0 mb30">{card.subtext[idx]}</p>
                <img src={item.url} alt="msp-logo" />
              </div>
              <img
                src={card.picture[idx].url}
                alt={card.text[idx]}
                className="position-absolute msp-owner"
              />
            </>
          ) : (
            <img src={item.url} alt="msp-logo" />
          )}
        </div>
      </Col>
    )
  }
  const PopUpForm = () => {
    return (
      <div className="popup-signup">
        <div class="wrap position-fixed"></div>
        <Container className="position-fixed">
          <div className="Form-box position-relative">
            <SVGIcon
              name="close-icon-black"
              className="close-icon position-absolute"
              onClick={() => {
                setPopUp(false)
                setSuccess(false)
              }}
            />
            {!success ? (
              <>
                <FormBuilder
                  formClassName="forms"
                  errorClassName="error-message"
                  inputErrorClassName="error-active"
                  data={formdata}
                  endpoint={process.env.HUBSPOT_SUBSCRIPTION_ENDPOINT}
                  url={process.env.HUBSPOT_MSP_AWARDS_URL}
                  formName="Awards Page Subscription - SuperOps"
                  buttonText={
                    loading ? (
                      <div className="loading-anim">
                        LOADING
                        <span className="ellipsis-anim">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </span>
                      </div>
                    ) : (
                      "SUBMIT"
                    )
                  }
                  buttonClassName="primary pink"
                  IpStackData
                  afterSubmit={() => setSuccess(true)}
                />
                <div className="disclaimer p12 a12 mt16">
                  <center>
                    <p>
                      By clicking "SUBMIT", you agree to SuperOps's{" "}
                      <a href="https://superops.com/terms" target="_blank">
                        Terms of use
                      </a>{" "}
                      and{" "}
                      <a href="https://superops.com/privacy" target="_blank">
                        Privacy policy
                      </a>
                      .
                    </p>
                  </center>
                </div>
              </>
            ) : (
              <>
                <div className="success-box text-center">
                  <img src={Popper} alt="popper" />

                  <p className="ty font-roboto fw-600 mt16 mx-auto">
                    We’ll keep you posted about our upcoming MSP Awards!
                  </p>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <StaticQuery
        query={graphql`
          query AwardsWinner2023 {
            SuperOps {
              pageComponents(where: { pageName: "MSP AWARDS WINNER 2023" }) {
                sectionName
                seo {
                  keywords
                  description
                  title
                  image {
                    id
                    url
                  }
                }
                cards {
                  cardName
                  text
                  subtext
                  image {
                    url
                  }
                  picture {
                    url
                  }
                }
                images {
                  url
                }
                card {
                  heading
                  text
                  content {
                    html
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pageComponents.map(page => {
              const { seo, sectionName, cards, images, card } = page
              return (
                <div className="mspAwards-2023 bg-dark awards-winner">
                  <Frame
                    seo={seo}
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                    twitterOG
                  >
                    {popUp && <PopUpForm />}

                    <header>
                      <Navigation
                        page="Supersummit-2022"
                        transparentScroll
                        noNavTabs
                      />
                    </header>
                    <section className="Hero position-relative">
                      <video
                        className="bg-video d-dsk"
                        autoPlay
                        muted
                        loop
                        id="bg-video"
                      >
                        <source
                          src={
                            "https://media.graphassets.com/I5V3nnThRmWrRag5eNoE"
                          }
                          type="video/mp4"
                        />
                      </video>
                      <div className="gradient"></div>
                      <Container>
                        <div className="wrapper position-relative">
                          <iframe
                            src="https://rive.app/s/YmTBWGIRvkCbY2Fc6WRJwA/embed"
                            className="award-coin"
                          ></iframe>
                          <h1 className="hero-text fw-bold font-roboto down-to-up-2">
                            {sectionName}
                          </h1>
                        </div>
                      </Container>
                    </section>

                    <section className="prize-announcement">
                      <Container className="Layout-container altv3">
                        {cards.map(card => {
                          return (
                            <>
                              <div className="dspnone">
                                <ScrollLink
                                  to={card.cardName.split(" ").join("-")}
                                  hashSpy={true}
                                >
                                  <div></div>
                                </ScrollLink>
                              </div>
                              <ScrollElement
                                name={card.cardName.split(" ").join("-")}
                              >
                                <Slide style={{ "--sal-delay": "0.2s" }}>
                                  <h1 className="font-roboto fw-bold category-heading position-relative mb50">
                                    {card.cardName}
                                  </h1>
                                </Slide>
                                <Slide style={{ "--sal-delay": "0.2s" }}>
                                  <div className="d-flex flex-wrap award-card-wrapper d-dsk">
                                    {card.image.map((item, idx) => {
                                      return (
                                        <AwardCards
                                          card={card}
                                          item={item}
                                          images={images}
                                          idx={idx}
                                        />
                                      )
                                    })}
                                  </div>
                                </Slide>
                                <Row className="d-mob mb100">
                                  <CarouselComponent
                                    status
                                    swipeable
                                    responsive={responsiveSpeaker}
                                    autoPlay={false}
                                  >
                                    {card.image.map((item, idx) => {
                                      return (
                                        <AwardCards
                                          card={card}
                                          item={item}
                                          images={images}
                                          idx={idx}
                                        />
                                      )
                                    })}
                                  </CarouselComponent>
                                </Row>
                              </ScrollElement>
                            </>
                          )
                        })}
                      </Container>
                    </section>

                    <section className="stay-updated">
                      <Container className="Layout-container altv3 text-center">
                        <Fade bottom distance="20px">
                          <h2 className="font-roboto mb30 fw-bold heading mx-auto m-0">
                            {card.heading[0]}
                          </h2>
                        </Fade>
                        <Fade bottom distance="20px">
                          <Buttons
                            theme="primary-new register-button mb30"
                            text={card.text[0]}
                            onClick={() => showpopup()}
                          />
                        </Fade>

                        <Fade bottom distance="20px">
                          <p className="description">
                            {parse(card.content[0].html)}
                          </p>
                        </Fade>
                      </Container>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default AwardsWinner2023
